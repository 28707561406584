'use client';

import { Simplify } from '@/prismicio-types';
import { Content, GroupField } from '@prismicio/client';
import React from 'react';
import useSWR from 'swr';
import { getLocationFees } from '@/utils/swrRequests';
import { ROUTES } from '@/routes';
import DesktopTable from '@/components/molecules/DesktopTable';
import MobileCrypto from '@/components/molecules/MobileCrypto';
import { useLang } from '@/hooks/useLang';
import { useGetCryptos } from '@/hooks/UseGetCryptos';
import FeesTables from '../FeesTables';
import { CryptoTableType } from '@/utils/types';
type Props = {
  coins: GroupField<Simplify<Content.CoinsDocumentDataCoinsItem>>;
  variant: 'sellTable' | 'buyTable' | 'sellFeesAndLimits' | 'buyFeesAndLimits';
  headers: string[];
  howToButtonLabelMap: Record<string, string>;
};
const CryptoTableWrapper = ({
  coins,
  headers,
  variant,
  howToButtonLabelMap
}: Props) => {
  const lang = useLang();
  const {
    data: location
  } = useSWR([ROUTES.APILocationsFees, lang], async () => await getLocationFees(lang), {
    refreshInterval: 10000,
    fallbackData: {
      result: null,
      isLoading: true
    }
  });
  const {
    data: rows
  } = useGetCryptos({
    lang,
    location,
    coins,
    filter: variant == 'sellTable' ? 'sellOnly' : 'all'
  });
  const getHowToButtonLabel: CryptoTableType['howToButtonLabel'] = ticker => howToButtonLabelMap[ticker] || '';
  return <>
      {rows?.error ? <div className="o-container o-container--xl my-8">
          <div className="flex items-center justify-center rounded-lg border border-gray-200 p-8 text-body-7 font-bold">
            {rows?.error}
          </div>
        </div> : variant === 'buyTable' || variant === 'sellTable' ? <>
          <DesktopTable rows={rows?.result?.filter(row => row.sellValue !== '-' || variant !== 'sellTable')} variant={variant} headers={headers} isLoading={rows?.isLoading} howToButtonLabel={getHowToButtonLabel} />
          <MobileCrypto rows={rows?.result?.filter(row => row.sellValue !== '-' || variant !== 'sellTable')} variant={variant} headers={headers} isLoading={rows?.isLoading} howToButtonLabel={getHowToButtonLabel} />
        </> : <FeesTables rows={rows?.result} variant={variant} isLoading={rows?.isLoading} headers={headers} />}
    </>;
};
export default CryptoTableWrapper;