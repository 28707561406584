import React from 'react';
import clsx from 'clsx';
import TableBody from '@/components/molecules/TableBody';
import { CryptoTableType } from '@/utils/types';
import FeesBody from '@/components/molecules/FeesBody';
import DesktopTableSkeleton from './partials/DesktopTableSkeleton';
const DesktopTable = ({
  headers,
  rows,
  variant,
  feesRow,
  tooltip,
  howToButtonLabel,
  isLoading
}: CryptoTableType) => {
  if (isLoading) {
    return <DesktopTableSkeleton />;
  }
  return <table className="crypto-table mt-8 hidden w-full table-fixed border-separate border-spacing-0 lg:table" data-sentry-component="DesktopTable" data-sentry-source-file="index.tsx">
      <thead className="text-primary-600">
        <tr className="first:child:pl-6 last:child:pr-6 last:child:text-end">
          {feesRow && <th className="w-1/3 bg-primary-200 py-3.5 text-left text-h8 font-bold first:rounded-l-lg last:rounded-r-lg" scope="col">
              <div className="hidden items-center gap-x-2 lg:flex">
                {feesRow}
                {tooltip}
              </div>
            </th>}
          {headers.map(header => <th className={clsx('bg-primary-200 py-3.5 text-left text-h8 font-bold first:rounded-l-lg last:rounded-r-lg', {
          'w-1/4': header === 'Name' && (variant == 'buyTable' || variant == 'sellTable')
        })} key={header} scope="col">
              {header}
            </th>)}
        </tr>
      </thead>
      {variant == 'buyTable' || variant == 'sellTable' ? <TableBody rows={rows} variant={variant} howToButtonLabel={howToButtonLabel} /> : <FeesBody feesRow={feesRow} rows={rows} variant={variant} howToButtonLabel={howToButtonLabel} />}
    </table>;
};
export default DesktopTable;