'use client';

import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import { FormInput } from '@/components/atoms/Input';
import { PrismicNextImage } from '@prismicio/next';
import { FormSelect } from '@/components/atoms/Select';
import { FormTextArea } from '@/components/atoms/TextArea';
import Button from '@/components/atoms/Button';
import ModalInfo from '@/components/molecules/ModalInfo';
import { SelectInputItem } from '@/components/atoms/Select/types';
import useRWD from '@/hooks/useRWD';
import useRecaptcha from '@/hooks/useRecaptcha';
import { useHostArmForm } from './hooks';
import { HostAtmFormProps } from '../..';
interface HostAtmFormInnerProps extends Pick<HostAtmFormProps, 'slice'> {
  storeTypesOptions: SelectInputItem[];
  labels: {
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    phoneNumber: string;
    storeType: string;
    country: string;
    provinceOrState: string;
    city: string;
    storeAddress: string;
    googleBusinessProfileLink: string;
    notes: string;
  };
  placeholders: {
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    phoneNumber: string;
    country: string;
    provinceOrState: string;
    city: string;
    storeAddress: string;
    googleBusinessProfileLink: string;
    notes: string;
  };
  submitButtonLabel: string;
  modal: {
    success: {
      title: string;
      description: string;
    };
    error: {
      title: string;
      description: string;
    };
  };
}
const HostAtmFormInner = ({
  slice,
  storeTypesOptions,
  labels,
  placeholders,
  submitButtonLabel,
  modal
}: HostAtmFormInnerProps): JSX.Element => {
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const {
    isMobile
  } = useRWD();
  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      phoneNumber: '',
      storeType: storeTypesOptions[0].value,
      country: '',
      provinceOrState: '',
      city: '',
      storeAddress: '',
      googleBusinessProfileLink: '',
      notes: ''
    }
  });
  const {
    reset: resetForm
  } = methods;
  const {
    onSubmit,
    isLoading
  } = useHostArmForm();
  const {
    recaptchaRef,
    recaptchaToken,
    handleRecaptchaChange,
    handleRecaptchaExpired,
    resetRecaptcha
  } = useRecaptcha();
  return <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation} className="o-container o-container--xl my-44" data-sentry-component="HostAtmFormInner" data-sentry-source-file="index.tsx">
      <div className="flex flex-col gap-5 lg:flex-row">
        <div className="flex items-center justify-center rounded-[.625rem] border border-gray-200 bg-white">
          <PrismicNextImage field={slice.primary.image} className="h-[500px] w-[282px] object-contain lg:h-[670px] lg:w-[440px]" data-sentry-element="PrismicNextImage" data-sentry-source-file="index.tsx" />
        </div>
        <div className="flex flex-1 flex-col rounded-[.625rem] border border-gray-200 bg-white px-6 py-9 lg:p-10">
          <h3 className="mb-10 text-center text-h5 font-bold leading-9 text-gray-600 lg:text-body-2">
            {slice.primary.title}
          </h3>
          <FormProvider {...methods} data-sentry-element="FormProvider" data-sentry-source-file="index.tsx">
            <form onSubmit={methods.handleSubmit(async data => {
            try {
              if (!recaptchaToken) {
                setIsModalErrorOpen(true);
                return;
              }
              await onSubmit(data, recaptchaToken);
              setIsModalSuccessOpen(true);
              resetRecaptcha();
              resetForm();
            } catch (error) {
              setIsModalErrorOpen(true);
            }
          })} id="applyForm">
              <div className="mb-5 flex w-full flex-col gap-5 lg:mb-6 lg:flex-row lg:gap-3">
                <FormInput name="firstName" label={labels.firstName} placeholder={placeholders.firstName} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
                <FormInput name="lastName" label={labels.lastName} placeholder={placeholders.lastName} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
              </div>
              <div className="mb-5 flex w-full flex-col gap-5 lg:mb-6 lg:flex-row lg:gap-3">
                <FormInput name="email" label={labels.email} placeholder={placeholders.email} type="email" required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
                <FormInput name="companyName" label={labels.companyName} placeholder={placeholders.companyName} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormInput name="phoneNumber" label={labels.phoneNumber} placeholder={placeholders.phoneNumber} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormSelect name="storeType" label={labels.storeType} options={storeTypesOptions} data-sentry-element="FormSelect" data-sentry-source-file="index.tsx" />
              </div>
              <div className="mb-5 flex w-full flex-col gap-5 lg:mb-6 lg:gap-3 xl:flex-row">
                <FormInput name="country" label={labels.country} placeholder={placeholders.country} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
                <FormInput name="provinceOrState" label={labels.provinceOrState} placeholder={placeholders.provinceOrState} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
                <FormInput name="city" label={labels.city} placeholder={placeholders.city} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormInput name="storeAddress" label={labels.storeAddress} placeholder={placeholders.storeAddress} required data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormInput name="googleBusinessProfileLink" label={labels.googleBusinessProfileLink} placeholder={placeholders.googleBusinessProfileLink} data-sentry-element="FormInput" data-sentry-source-file="index.tsx" />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormTextArea name="notes" label={labels.notes} placeholder={placeholders.notes} data-sentry-element="FormTextArea" data-sentry-source-file="index.tsx" />
              </div>
              <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''} onChange={handleRecaptchaChange} onExpired={handleRecaptchaExpired} className="mb-10" size={isMobile ? 'compact' : 'normal'} data-sentry-element="ReCAPTCHA" data-sentry-source-file="index.tsx" />
              <Button type="submit" className="w-full" disabled={!recaptchaToken || isLoading} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                <span>{submitButtonLabel}</span>
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
      <ModalInfo isOpen={isModalSuccessOpen} onClose={() => setIsModalSuccessOpen(false)} {...modal.success} data-sentry-element="ModalInfo" data-sentry-source-file="index.tsx" />
      <ModalInfo isOpen={isModalErrorOpen} onClose={() => setIsModalErrorOpen(false)} {...modal.error} data-sentry-element="ModalInfo" data-sentry-source-file="index.tsx" />
    </section>;
};
export default HostAtmFormInner;