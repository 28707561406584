'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { format } from 'url';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useRouter } from 'next/navigation';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import useUserLocation from '@/hooks/useUserLocation';
import { ArrowRightIcon, MapPinIcon } from '@/public/assets/svg';
import { checkIfIOS, disableIosTextFieldZoom } from '@/utils/safari';
import { useLoadGoogleMapsScript } from '@/hooks/useLoadGoogleMapsScript';
import { useLang } from '@/hooks/useLang';
import { ROUTES } from '@/routes';
import { BaseAutocompleteItem } from '@/components/atoms/Autocomplete/types';
import { GoogleMapsScriptLoaderProps, LocationSelectProps, Option } from './types';
import { addLocale } from '@/utils/locales';
const GoogleMapsScriptLoader: React.FC<GoogleMapsScriptLoaderProps> = ({
  init
}) => {
  const initCalledRef = useRef(false);
  const {
    isLoaded
  } = useLoadGoogleMapsScript();
  useEffect(() => {
    if (!isLoaded || initCalledRef.current) {
      return;
    }
    initCalledRef.current = true;
    init();
  }, [init, isLoaded]);
  return null;
};
const LocationSelect = ({
  placeholder,
  className
}: LocationSelectProps) => {
  const router = useRouter();
  const lang = useLang();
  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [currentLocation, setCurrentLocation] = useState<Option | null>(null);
  const [loadScript, setLoadScript] = useState(false);
  const {
    data: userLocation
  } = useUserLocation();
  const {
    ready,
    setValue: setGooglePlacesValue,
    suggestions: {
      data
    },
    clearSuggestions,
    init
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: false
  });
  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  const handleSubmit = async () => {
    const placeId = selectedOption?.value;
    const geocode = await getGeocode({
      placeId
    });
    const {
      lat,
      lng
    } = getLatLng(geocode[0]);
    const url = format({
      pathname: addLocale(ROUTES.bitcoinATM(lang), lang),
      query: {
        latitude: lat,
        longitude: lng,
        placeId
      }
    });
    router.push(url);
  };
  const options = useMemo(() => {
    if (!ready) return [];
    if (data.length === 0 && currentLocation) {
      return [currentLocation];
    }
    return data.map(suggestion => {
      const {
        place_id,
        structured_formatting: {
          main_text,
          secondary_text
        }
      } = suggestion;
      return {
        label: [main_text, secondary_text].filter(Boolean).join(' ') || 'Missing label',
        value: place_id
      };
    });
  }, [ready, data, currentLocation]);
  useEffect(() => {
    if (!ready) {
      return;
    }
    const asyncFn = async () => {
      if (!userLocation) {
        setCurrentLocation(null);
        return;
      }
      try {
        const {
          latitude,
          longitude
        } = userLocation;
        const geocode = await getGeocode({
          location: {
            lat: latitude,
            lng: longitude
          }
        });
        const {
          place_id,
          formatted_address
        } = geocode[0];
        setCurrentLocation({
          label: formatted_address,
          value: place_id
        });
      } catch {
        setCurrentLocation(null);
      }
    };
    asyncFn();
  }, [ready, userLocation]);
  useEffect(() => {
    if (checkIfIOS()) {
      disableIosTextFieldZoom();
    }
  }, []);
  return <div className={clsx('relative', className)} {...{
    ref
  }} data-sentry-component="LocationSelect" data-sentry-source-file="index.tsx">
      <Combobox value={selectedOption} onChange={option => {
      setSelectedOption(option);
      clearSuggestions();
    }} virtual={{
      options
    }} onClose={() => setIsFocused(false)} immediate data-sentry-element="Combobox" data-sentry-source-file="index.tsx">
        <div className="relative">
          <div className={clsx('relative w-full cursor-default overflow-hidden rounded-md border border-gray-200 bg-white text-left hover:border-gray-300', {
          'rounded-b-none': isFocused
        })}>
            <div className="absolute bottom-0 left-3.5 top-0 flex items-center justify-center">
              <MapPinIcon width={16} height={16} data-sentry-element="MapPinIcon" data-sentry-source-file="index.tsx" />
            </div>
            <ComboboxInput className={clsx('w-full border-none py-3.5 pl-10 pr-12 text-button text-gray-600 caret-primary-600 shadow-none focus:outline-none')} placeholder={placeholder} onChange={event => {
            const newValue = event.target.value;
            setGooglePlacesValue(newValue);
          }} displayValue={() => {
            if (!selectedOption) return '';
            return selectedOption.label;
          }} onFocus={() => {
            setIsFocused(true);
            setGooglePlacesValue('');
            setSelectedOption(null);
            setLoadScript(true);
          }} onBlur={() => setIsFocused(false)} data-sentry-element="ComboboxInput" data-sentry-source-file="index.tsx" />
          </div>
          <div className="absolute bottom-0 right-1.5 top-0 flex items-center justify-center">
            <button name="select-submit" disabled={!selectedOption} onClick={() => {
            handleSubmit();
          }} className={clsx('flex h-9 w-9 items-center justify-center rounded-full', {
            'bg-primary-600': selectedOption,
            'bg-gray-200': !selectedOption
          })} aria-label="select-submit">
              <ArrowRightIcon width={24} height={24} data-sentry-element="ArrowRightIcon" data-sentry-source-file="index.tsx" />
            </button>
          </div>
          <ComboboxOptions className="absolute z-50 max-h-60 w-full overflow-auto rounded-md rounded-t-none border border-t-0 border-gray-200 bg-white" data-sentry-element="ComboboxOptions" data-sentry-source-file="index.tsx">
            {({
            option
          }) => <ComboboxOption value={option} className="relative w-full cursor-pointer select-none px-3 py-3.5 text-button text-gray-600 hover:bg-gray-50">
                {(option as BaseAutocompleteItem).label}
              </ComboboxOption>}
          </ComboboxOptions>
        </div>
      </Combobox>
      {loadScript && <GoogleMapsScriptLoader init={init} />}
    </div>;
};
export default LocationSelect;