'use client';

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { CryptoTableType } from '@/utils/types';
import { PrismicNextImage } from '@prismicio/next';
const FeesBody = ({
  variant,
  rows,
  feesRow
}: Omit<CryptoTableType, 'headers'>) => {
  const centerRows = useMemo(() => {
    return variant == 'buyFeesAndLimits' ? rows?.map(item => {
      const extra_coin = item.coin_extras?.find(coin => coin.service_name == feesRow);
      const transaction_limit = `${extra_coin?.min_transaction_limit} - ${extra_coin?.max_transaction_limit}`;
      return [item.buyValue, item.buyFeeFlat, transaction_limit, extra_coin?.daily_limit];
    }) || [] : rows?.map(item => {
      const extra_coin = item.coin_extras?.find(coin => coin.service_name == feesRow);
      const transaction_limit = `${extra_coin?.min_transaction_limit} - ${extra_coin?.max_transaction_limit}`;
      return [item.sellValue, transaction_limit, extra_coin?.daily_limit];
    }) || [];
  }, [feesRow, rows, variant]);
  return <tbody className="shadow-elevation-3 before:block before:h-4" data-sentry-component="FeesBody" data-sentry-source-file="index.tsx">
      {rows?.map((row, rowIndex) => {
      return <tr className="h-[4.5rem] border-gray-200 child:h-[inherit] child:p-0 child:child:first:border-t-0 child:first:border-t first:child:border-l first:child:pl-6 child:last:border-b last:child:border-r last:child:pr-6" key={row.ticker}>
            <td>
              <div className="flex h-full items-center gap-x-3 border-t">
                <PrismicNextImage width={28} height={28} field={row.icon} />
                {`${row.label} (${row.ticker}/${row.currencyCode})`}
              </div>
            </td>
            {centerRows[rowIndex]?.map((item, itemIndex) => <td key={item}>
                <div className={clsx('flex h-full items-center border-t', {
            'justify-end': itemIndex === centerRows[rowIndex].length - 1
          })}>
                  {item}
                </div>
              </td>)}
          </tr>;
    })}
    </tbody>;
};
export default FeesBody;