import { Lang } from '@/types/locales';

export const formatTime = <T extends string | null | undefined>(timeString: T, lang: Lang): T => {
  if (typeof timeString !== 'string') {
    return timeString;
  }

  const [time, period] = timeString.trim().split(' ');

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':').map((numberString) => {
    const numberValue = parseInt(numberString, 10);

    return Number.isNaN(numberValue) ? 0 : numberValue;
  });

  const now = new Date();

  if (hours == 12) {
    hours = 0;
  }

  if (period.toLowerCase() === 'pm') {
    hours += 12;
  }

  now.setHours(hours, minutes);

  return new Intl.DateTimeFormat(lang, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(now) as T;
};
