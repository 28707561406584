import React from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { useTranslation } from 'react-i18next';
import Tag from '@/components/atoms/Tag';
import Link from '@/components/atoms/Link';
import { ArrowRightIcon } from '@/public/assets/svg';
import { ROUTES } from '@/routes';
import { BlogListItemProps, Category } from './types';
const BlogListItem = async ({
  item
}: BlogListItemProps) => {
  const data = item?.data?.tags[0] as Category;
  const {
    t
  } = useTranslation();
  return <Link href={ROUTES.blogPost(item.uid)} className="flex w-full max-w-full flex-col overflow-hidden rounded-[0.625rem] border border-gray-200 shadow-elevation-4 hover:border-primary-500 sm:max-w-[500px] lg:max-w-[363px]" data-sentry-element="Link" data-sentry-component="BlogListItem" data-sentry-source-file="index.tsx">
      <div className="w-full lg:max-h-60 lg:min-h-60">
        <PrismicNextImage fallback={<div className="h-full w-full bg-gray-200" />} className="aspect-[3/2] h-full w-full object-cover" field={item.data.image} data-sentry-element="PrismicNextImage" data-sentry-source-file="index.tsx" />
      </div>
      <div className="flex h-full flex-col justify-between bg-white px-5 py-6 lg:px-6">
        <div className="mb-4 flex items-center gap-2 text-gray-500">
          {!!data?.category.data?.title && <Tag className="w-min" variant={data?.variant || 'orange'}>
              {data.category.data.title}
            </Tag>}
          {item.data.read_time && <>
              •{' '}
              <p className="text-h9">
                {t('common.readTimeLabel', {
              readTime: item.data.read_time
            })}
              </p>
            </>}
        </div>
        <h3 className="mb-3 line-clamp-2 text-body-5 font-bold text-gray-600 child:inline lg:min-h-12">
          {item.data.title}
        </h3>
        <div className="mb-5 lg:min-h-16">
          <p className="line-clamp-3 text-body-7 text-gray-500 child:inline">
            {item.data.description}
          </p>
        </div>
        <div className="flex items-center text-gray-600 hover:opacity-50">
          {t('common.readMoreLabel')} <ArrowRightIcon data-sentry-element="ArrowRightIcon" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </Link>;
};
export default BlogListItem;