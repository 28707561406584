import useSWRMutation from 'swr/mutation';

import { ROUTES } from '@/routes';
import { WitchReCaptcha } from '@/types/global';
import { RECAPTCHA_HEADER } from '@/app/api/consts';

import { ContactHostATMPostBody } from '../../types';

export const useHostArmForm = () => {
  const { trigger, isMutating } = useSWRMutation(
    ROUTES.APIHostATMForm,
    async (url, { arg }: { arg: WitchReCaptcha<ContactHostATMPostBody> }) => {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          [RECAPTCHA_HEADER]: arg.recaptchaToken,
        },
        body: JSON.stringify(arg),
      });

      if (!response.ok) {
        throw Error('Form request failed');
      }

      return true;
    },
  );

  const onSubmit = async (data: ContactHostATMPostBody, recaptchaToken: string) => {
    await trigger({ ...data, recaptchaToken });
  };

  return { onSubmit, isLoading: isMutating };
};
